import React from 'react';
import { navigate } from 'gatsby';

import { isBrowser, isLoggedIn } from '../utils/auth';

const ProtectedRoute = ({ component: Component, ...rest }) => {
	if (!isLoggedIn() && isBrowser && window.location.pathname !== '/app/login') {
		// If we’re not logged in, redirect to the login page.
		navigate('/app/login');
		return null;
	}

	return <Component {...rest} />;
};

export default ProtectedRoute;
