import React, { useEffect, useState } from 'react';

import DocumentsTable from '../DocumentsTable';
import Layout from '../Layout';

import { listObjectsByPrefix } from '../../utils/file-repo';

import * as styles from '../../styles/pages/DocumentPages.module.css';

const MediaSpendsPage = () => {
	const [documents, setDocuments] = useState([]);
	const pageName = 'media-spend-documents/';

	useEffect(() => {
		listObjectsByPrefix(pageName, setDocuments);
	}, []);

	return (
		<Layout
			className={styles.container}
			tableData={documents}
			setTableData={setDocuments}
			pageName={pageName}
			withNavMenu
			withAddDocumentsModal>
			<h1>MEDIA SPENDS DOCUMENTS</h1>
			<hr />
			<DocumentsTable tableData={documents} setTableData={setDocuments} pageName={pageName} withFiltering />
		</Layout>
	);
};

export default MediaSpendsPage;
