import React, { useEffect, useState } from 'react';
import { Storage } from 'aws-amplify';

import EnclosedCloseIcon from '../../assets/cross_enclosed.inline.svg';
import searchIcon from '../../assets/search.png';
import thumbsUpIcon from '../../assets/thumbs_up.png';

import Button from './Button';
import Modal from './Modal';
import TextInput from './TextInput';

import { listObjectsByPrefix, updateDocumentMetadata } from '../utils/file-repo';

import * as styles from '../styles/components/Modal.module.css';

const EditDocumentsModal = ({ isOpen, handleClose, rowData, pageName, setTableData }) => {
	const [step, setStep] = useState(1);
	const [selectedFile, setSelectedFile] = useState(null);
	const [errorMessage, setErrorMessage] = useState('');
	const [tagQuery, setTagQuery] = useState('');
	const [description, setDescription] = useState(rowData ? rowData.description : '');
	const [tagList, setTagList] = useState(rowData ? [rowData.tag] : []);

	const handleFileUploadChange = (event) => {
		setSelectedFile(event.target.files[0]);
	};

	const handleAddTagSubmit = (event) => {
		event.preventDefault();
		if (tagQuery && tagQuery.length > 1 && !tagList.includes(tagQuery) && tagList <= 1) {
			console.log([...tagList, tagQuery]);
			setTagList([...tagList, tagQuery]);
		}
	};

	const handleRemoveTag = (index) => {
		let arr = [...tagList];
		arr.splice(index, 1);
		setTagList(arr);
	};

	const handleTagSearchUpdate = (event) => {
		setTagQuery(event.target.value);
	};

	const handleDescriptionChange = (event) => {
		setDescription(event.target.value);
	};

	const handleEditDocumentSubmit = async () => {
		try {
			if (selectedFile) {
				// grab the extension from rowData.metadataId
				const extension = rowData.metadataId.split('.').pop();
				if(!selectedFile.name.endsWith(extension)) {
					throw "Cannot replace a file with different file type. Please upload another and try again";
				}
				// check to see if filename extensions match
				// if(selectedFile.name )
				
				const storageResponse = await Storage.put(rowData.metadataId, selectedFile);
				console.log('Uploaded file to S3: ', storageResponse);
				listObjectsByPrefix(pageName, setTableData);
			}
			if (tagList.length > 0 || description.length > 1) {
				const documentMetaUpdate = await updateDocumentMetadata(rowData.metadataId, description, tagList.join(','));
				console.log('Metadata updated', documentMetaUpdate);
				// TODO: Send out API call to attempt upload of document, show loading state, and add error handling/response
				listObjectsByPrefix(pageName, setTableData);
				
			} 
			setStep(2);
		} catch (err) {
			setErrorMessage(err)
		}
	};

	const resetEditForm = () => {
		setErrorMessage('');
		setStep(1);
		if (rowData) {
			setTagList([rowData.tag]);
			setDescription(rowData.description);
		} else {
			setSelectedFile(null);
			setTagList([]);
			setDescription('');
		}
	};

	useEffect(() => {
		resetEditForm();
	}, [isOpen, rowData]);

	const renderContents = () => {
		switch (step) {
			case 1:
				return (
					<div>
						<h2>EDIT DOCUMENT</h2>
						{rowData && <h3>{rowData.documentName}</h3>}
						<div className={styles.inputContainer}>
							<label htmlFor='file'>UPLOAD REPLACEMENT VERSION</label>
							{errorMessage && <p className={styles.error}>{errorMessage}</p>}
							<input id='file' type='file' name='file' onChange={handleFileUploadChange} />
						</div>
						<form className={styles.searchField} onSubmit={handleAddTagSubmit}>
							<TextInput
								type='search'
								color='light'
								label='EDIT TAG'
								id='search'
								value={tagQuery}
								handleChange={handleTagSearchUpdate}
							/>
							<Button type='submit' color='white'>
								ADD
								{/* <img src={searchIcon} alt='Search Icon' className={searchIcon} /> */}
							</Button>
						</form>
						<div className={styles.tagList}>
							{tagList
								.filter((t) => t)
								.map((tag, index) => (
									<div className={styles.tagListItem} key={index}>
										<button className={styles.tagDeleteButton} onClick={() => handleRemoveTag(index)}>
											<EnclosedCloseIcon />
										</button>
										{tag}
									</div>
								))}
						</div>
						<div className={styles.inputContainer}>
							<label htmlFor='description'>EDIT DESCRIPTION</label>
							<textarea
								id='description'
								name='description'
								placeholder='TYPE DESCRIPTION HERE /'
								value={description}
								onChange={handleDescriptionChange}
								rows='5'
								required></textarea>
						</div>
						<div className={styles.buttonBar}>
							<Button color='yellow' handleClick={handleClose}>
								CANCEL
							</Button>
							<Button type='submit' color='black' handleClick={handleEditDocumentSubmit}>
								COMMIT
							</Button>
						</div>
					</div>
				);
			case 2:
			default:
				return (
					<div className={styles.confirmation}>
						<img src={thumbsUpIcon} alt='Thumbs Up Icon' />
						<h3>DOCUMENT EDITED SUCCESSFULLY</h3>
						<Button color='yellow' handleClick={handleClose}>
							CLOSE
						</Button>
					</div>
				);
		}
	};

	// TODO: Add form validation, API integrations, error states, and loading spinner
	return <Modal isOpen={isOpen}>{renderContents()}</Modal>;
};

export default EditDocumentsModal;
