import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { navigate } from 'gatsby';

import Button from '../Button';
import Layout from '../Layout';
import TextInput from '../TextInput';

import * as styles from '../../styles/pages/FormPages.module.css';

const ForgotPasswordPage = () => {
	const [step, setStep] = useState(1);
	const [isLoading, setIsLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [formData, setFormData] = useState({ username: '', verificationCode: '', newPassword: '', confirmNewPassword: '' });
	const [formFieldError, setFormFieldError] = useState({
		username: false,
		verificationCode: false,
		newPassword: false,
		confirmNewPassword: false,
	});

	const isValidFieldFormat = (name, value) => {
		const usernameRegex = /^[a-zA-Z]{1}\.[a-zA-Z]+$/;
		const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{15,}$/;
		// TODO: Add verification code and password validation regex

		switch (name) {
			case 'username':
				return usernameRegex.test(value);
			case 'verificationCode':
				return value.length > 0;
			case 'newPassword':
				return passwordRegex.test(value);
			case 'confirmNewPassword':
			default:
				return value === formData.newPassword;
		}
	};

	const handleInputBlur = (event) => {
		// check input format and show error message if invalid
		setFormFieldError({ ...formFieldError, [event.target.name]: !isValidFieldFormat(event.target.name, event.target.value) });
	};

	const handleInputChange = (event) => {
		// dynamically set corresponding field value by name of input
		setFormData({ ...formData, [event.target.name]: event.target.value });
	};

	const handleUsernameFormSubmit = (event) => {
		// handle submit for step 1 form (sending verification code to email matchedto inputted username)
		event.preventDefault(); // prevent page refresh or url update on form submit
		if (formData.username) {
			setErrorMessage(null);
			setIsLoading(true);
			Auth.forgotPassword(formData.username)
				.then((data) => {
					console.log(data);
					setStep(2);
				})
				.catch((err) => {
					console.log(err);
					setErrorMessage(processErrorMessage(err.name));
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	};

	const handleNewPasswordFormSubmit = (event) => {
		// handle submit for step 2 form (updating user's password if verification code, username, and password meet acceptence criteria)
		event.preventDefault(); // prevent page refresh or url update on form submit
		if (formData.username && formData.verificationCode && formData.newPassword) {
			setErrorMessage(null);
			setIsLoading(true);
			Auth.forgotPasswordSubmit(formData.username, formData.verificationCode, formData.newPassword)
				.then((data) => {
					console.log(data);
					setStep(3);
				})
				.catch((err) => {
					console.log(err);
					setErrorMessage(processErrorMessage(err.name));
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	};

	const processErrorMessage = (error) => {
		switch (error) {
			case 'LimitExceededException':
				return 'Attempt limit exceeded, please try after some time.';
			case 'UserNotFoundException':
				return 'Could not find an account with that username. Please check and try again.';
			default:
				return 'Attempt failed. Please check information and try again.';
		}
	};

	const renderContent = () => {
		switch (step) {
			case 1:
				return (
					<>
						<h1>FORGOT PASSWORD</h1>
						<h2>ENTER YOUR USERNAME AND WE WILL SEND YOU A CODE NEEDED TO UPDATE YOUR PASSWORD</h2>
						{errorMessage && <p className={styles.error}>{errorMessage}</p>}
						<form onSubmit={handleUsernameFormSubmit}>
							<TextInput
								autoComplete='username'
								label='USERNAME /'
								id='username'
								value={formData.username}
								handleBlur={handleInputBlur}
								handleChange={handleInputChange}
								disabled={isLoading}
								pattern='^[a-zA-Z]{1}\.[a-zA-Z]+$'
								required
							/>
							{formFieldError['username'] && (
								<p className={styles.error}>Please ensure username in format of first_initial.lastname</p>
							)}
							<Button type='submit' color='yellow' disabled={isLoading}>
								SEND CODE
							</Button>
							<span>
								Don&apos;t have an account?{' '}
								<a href='mailto:julie.schenck@rapp.com?subject=ARNG SMSP Create Account Request&body=Hello,%0D%0DI would like to request an account to access the ARNG SMSP tool. Can you please create one for me?%0D%0DThank you,'>
									Create one here.
								</a>
							</span>
						</form>
					</>
				);
			case 2:
				return (
					<>
						<h1>UPDATE PASSWORD</h1>
						<h2>CHECK YOUR EMAIL FOR VERIFICATION CODE.</h2>
						<p>Didn’t receive email? </p>
						<ol>
							<li>Check all folders in case it was sent to spam</li>
							<li>
								Try <button onClick={() => setStep(1)}>resending</button>
							</li>
							<li>
								Are you sure you have an account?{' '}
								<a href='mailto:julie.schenck@rapp.com?subject=ARNG SMSP Create Account Request&body=Hello,%0D%0DI would like to request an account to access the ARNG SMSP tool. Can you please create one for me?%0D%0DThank you,'>
									Create a new account
								</a>
							</li>
							<li>
								Contact administrator at{' '}
								<a href='mailto:julie.schenck@rapp.com?subject=ARNG SMSP Support Request&body='>
									julie.schenck@rapp.com
								</a>
							</li>
						</ol>
						{errorMessage && <p className={styles.error}>{errorMessage}</p>}
						<form onSubmit={handleNewPasswordFormSubmit}>
							<TextInput
								autoComplete='one-time-code'
								label='VERIFICATION CODE /'
								id='verificationCode'
								value={formData.verificationCode}
								handleBlur={handleInputBlur}
								handleChange={handleInputChange}
								disabled={isLoading}
								pattern='^.{1,}$'
								required
							/>
							<TextInput
								autoComplete='new-password'
								type='password'
								label='NEW PASSWORD /'
								id='newPassword'
								value={formData.newPassword}
								handleBlur={handleInputBlur}
								handleChange={handleInputChange}
								disabled={isLoading}
								pattern='^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{15,}$'
								required
							/>
							{formFieldError['newPassword'] && (
								<p className={styles.error}>
									Password must be at least 15 characters in length, contain 1 uppercase, 1 lowercase, 1
									special, and 1 numeric character
								</p>
							)}
							<TextInput
								autoComplete='new-password'
								type='password'
								label='CONFIRM NEW PASSWORD /'
								id='confirmNewPassword'
								value={formData.confirmNewPassword}
								handleBlur={handleInputBlur}
								handleChange={handleInputChange}
								disabled={isLoading}
								pattern='^.{1,}$'
								required
							/>
							{formFieldError['confirmNewPassword'] && (
								<p className={styles.error}>Password confirmation must match new password input</p>
							)}
							<Button type='submit' color='yellow' disabled={isLoading}>
								CHANGE PASSWORD
							</Button>
						</form>
					</>
				);
			default:
				return (
					<>
						<h1>THANKS!</h1>
						<h2>PASSWORD CHANGED SUCCESSFULLY!</h2>
						<Button handleClick={() => navigate('/app/login')}>PROCEED TO LOGIN</Button>
					</>
				);
		}
	};

	return (
		<Layout className={styles.container}>
			{renderContent()}
			{isLoading && <div>Loading...</div>}
		</Layout>
	);
};

export default ForgotPasswordPage;
