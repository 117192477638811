import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { navigate } from 'gatsby';

import Button from '../Button';
import Layout from '../Layout';
import TextInput from '../TextInput';

import { setUser } from '../../utils/auth';

import * as styles from '../../styles/pages/FormPages.module.css';

const formDataDefault = { username: '', password: '', newPassword: '', confirmNewPassword: '' };
const formFieldErrorDefault = {
	username: false,
	password: false,
	newPassword: false,
	confirmNewPassword: false,
};

const LoginPage = () => {
	const [step, setStep] = useState(1);
	const [isLoading, setIsLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [formData, setFormData] = useState(formDataDefault);
	const [formFieldError, setFormFieldError] = useState(formFieldErrorDefault);
	const [userWithChallenge, setUserWithChallenge] = useState(null);

	const isValidFieldFormat = (name, value) => {
		const usernameRegex = /^[a-zA-Z]{1}.[a-zA-Z]+$/;
		const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{15,}$/;

		switch (name) {
			case 'username':
				return usernameRegex.test(value);
			case 'password':
				return value.length > 0;
			case 'newPassword':
				return passwordRegex.test(value);
			case 'confirmNewPassword':
			default:
				return value === formData.newPassword;
		}
	};

	const handleInputBlur = (event) => {
		// check input format and show error message if invalid
		setFormFieldError({ ...formFieldError, [event.target.name]: !isValidFieldFormat(event.target.name, event.target.value) });
	};

	const handleInputChange = (event) => {
		// dynamically set corresponding field value by name of input
		setFormData({ ...formData, [event.target.name]: event.target.value });
	};

	const handleLoginFormSubmit = (event) => {
		event.preventDefault(); // prevent page refresh or url update on form submit
		if (formData.username && formData.password) {
			setErrorMessage(null);
			setIsLoading(true);
			Auth.signIn(formData.username, formData.password)
				.then((response) => {
					if (response.challengeName && response.challengeName === 'NEW_PASSWORD_REQUIRED') {
						setUserWithChallenge(response);
						setStep(2);
					} else {
						if (response.username) {
							setUser(response);
							navigate('/app');
						}
					}
				})
				.catch((err) => {
					console.error(err);
					setErrorMessage(processErrorMessage(err.name));
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	};

	const handleCompletePasswordSubmit = (event) => {
		event.preventDefault();
		if (formData.newPassword && formData.confirmNewPassword) {
			setErrorMessage(null);
			setIsLoading(true);
			Auth.completeNewPassword(
				userWithChallenge, // the Cognito User Object
				formData.newPassword,
			)
				.then(() => {
					setStep(3);
				})
				.catch((err) => {
					console.error(err);
					setErrorMessage(err.message);
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	};

	const processErrorMessage = (error) => {
		switch (error) {
			case 'NotAuthorizedException':
				return 'Username or password is incorrect. Please check and try again.';
			case 'UserNotFoundException':
				return 'Could not find an account with that username. Please check and try again.';
			default:
				return 'Login attempt failed. Please check information and try again.';
		}
	};

	const renderContent = () => {
		switch (step) {
			case 1:
				return (
					<>
						<h1>LOGIN</h1>
						<h2>PLEASE ENTER YOUR EMAIL AND PASSWORD TO ACCESS SMSP</h2>
						{errorMessage && <p className={styles.error}>{errorMessage}</p>}
						<form onSubmit={handleLoginFormSubmit}>
							<TextInput
								autoComplete='username'
								label='USERNAME /'
								id='username'
								value={formData.username}
								handleBlur={handleInputBlur}
								handleChange={handleInputChange}
								pattern='^[A-Za-z]{1}\.[A-Za-z]{1,}$'
								disabled={isLoading}
								required
							/>
							{formFieldError['username'] && (
								<p className={styles.error}>Please enter username in format of first_initial.lastname</p>
							)}
							<TextInput
								autoComplete='current-password'
								type='password'
								label='PASSWORD /'
								id='password'
								value={formData.password}
								handleBlur={handleInputBlur}
								handleChange={handleInputChange}
								disabled={isLoading}
								required
							/>
							{formFieldError['password'] && <p className={styles.error}>Please enter password</p>}
							<Button type='submit' color='yellow' disabled={isLoading}>
								LOGIN
							</Button>
						</form>
						<span>
							<a href='mailto:julie.schenck@rapp.com?subject=ARNG SMSP Create Account Request&body=Hello,%0D%0DI would like to request an account to access the ARNG SMSP tool. Can you please create one for me?%0D%0DThank you,'>
								Create an account
							</a>
							{'|'}
							<a href='/app/forgot-password'>Forgot Password?</a>
						</span>
					</>
				);
			case 2:
				return (
					<>
						<h1>CREATE NEW PASSWORD</h1>
						<h2>TO LOGIN, YOU MUST FIRST CHANGE YOUR PASSWORD FROM THE TEMPORARY PASSWORD PROVIDED</h2>
						{errorMessage && <p className={styles.error}>{errorMessage}</p>}
						<form onSubmit={handleCompletePasswordSubmit}>
							<TextInput
								autoComplete='new-password'
								type='password'
								label='NEW PASSWORD /'
								id='newPassword'
								value={formData.newPassword}
								handleBlur={handleInputBlur}
								handleChange={handleInputChange}
								disabled={isLoading}
								pattern='^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{15,}$'
								required
							/>
							{formFieldError['newPassword'] && (
								<p className={styles.error}>
									Password must be at least 15 characters in length, contain 1 uppercase, 1 lowercase, 1
									special, and 1 numeric character
								</p>
							)}
							{formData.newPassword === formData.password && (
								<p className={styles.error}>Must enter different password than provided temporary password</p>
							)}
							<TextInput
								autoComplete='new-password'
								type='password'
								label='CONFIRM NEW PASSWORD /'
								id='confirmNewPassword'
								value={formData.confirmNewPassword}
								handleBlur={handleInputBlur}
								handleChange={handleInputChange}
								disabled={isLoading}
								pattern='^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{15,}$'
								required
							/>
							{formFieldError['confirmNewPassword'] && (
								<p className={styles.error}>Password confirmation must match new password input</p>
							)}
							<Button type='submit' color='yellow' disabled={isLoading}>
								CHANGE PASSWORD
							</Button>
						</form>
					</>
				);
			default:
				return (
					<>
						<h1>THANKS!</h1>
						<h2>PASSWORD CHANGED SUCCESSFULLY!</h2>
						<Button handleClick={() => setStep(1)}>PROCEED TO LOGIN</Button>
					</>
				);
		}
	};

	useEffect(() => {
		setFormData({ ...formData, newPassword: '', confirmNewPassword: '' });
		setFormFieldError(formFieldErrorDefault);
	}, [step]);

	return <Layout className={styles.container}>{renderContent()}</Layout>;
};

export default LoginPage;
