import React from 'react';

import * as styles from '../styles/components/RadioInput.module.css';

const RadioInput = ({ children, id, name, className, handleChange, checked }) => {
	return (
		<div className={`${styles.radio}${className ? ` ${className}` : ''}`}>
			<input type='radio' id={id} name={name} value={id} onChange={handleChange} checked={checked} />
			<label htmlFor={id}>{children}</label>
		</div>
	);
};

export default RadioInput;
