import React, { useEffect, useState } from 'react';

import contactsIcon from '../../../assets/category-icons/contacts.png';
import mediaSpendsIcon from '../../../assets/category-icons/media_spends.png';
import planningDocumentsIcon from '../../../assets/category-icons/planning_documents.png';
import reportingDocumentsIcon from '../../../assets/category-icons/reporting_documents.png';
import trainingDocumentsIcon from '../../../assets/category-icons/training_documents.png';

import DocumentsTable from '../DocumentsTable';
import Layout from '../Layout';

import { listUserRecentDocuments } from '../../utils/file-repo';
import { getCurrentUser } from '../../utils/auth'

import * as styles from '../../styles/pages/Home.module.css';

const HomePage = () => {
	const categories = [
		{
			text: 'PLANNING DOCUMENTS',
			href: '/app/planning-documents',
			icon: planningDocumentsIcon,
			altText: 'Planning Documents Icon',
		},
		{
			text: 'TRAINING DOCUMENTS',
			href: '/app/training-documents',
			icon: trainingDocumentsIcon,
			altText: 'Training Documents Icon',
		},
		{
			text: 'REPORTING DOCUMENTS',
			href: '/app/reporting-documents',
			icon: reportingDocumentsIcon,
			altText: 'Reporting Documents Icon',
		},
		{ text: 'MEDIA SPENDS', href: '/app/media-spends', icon: mediaSpendsIcon, altText: 'Media Spends Icon' },
		{
			text: 'CONTACTS',
			href: 'https://oneomnicom.sharepoint.com/:x:/r/sites/ARNGSMSP/Shared%20Documents/Support%20Tool/Contact%20List/MNCO_CONTACT_LIST_SupportTool.xlsx?d=wb2757726716c4c32ae153cf21db1135b&csf=1&web=1&e=DscUlv',
			icon: contactsIcon,
			altText: 'Contacts Icon',
		},
	];

	// TODO: Determine documents used for home page
	const [documents, setDocuments] = useState([]);

	useEffect(async () => {
		const docList = await listUserRecentDocuments(getCurrentUser().username);
		console.log('DocList that returned from the call: ', docList);
		setDocuments(docList);
	}, []);

	return (
		<Layout withNavMenu className={styles.home}>
			<h1>CATEGORIES</h1>
			<ul className={styles.categories}>
				{categories.map((category, index) => (
					<li key={index}>
						<a href={category.href}>
							<img src={category.icon} alt={category.altText} />
							<span>{category.text}</span>
						</a>
					</li>
				))}
			</ul>
			<h1>QUICK LINKS</h1>
			<ul className={styles.quickLinks}>
				<li>
					<a href='https://guard-support.myadexpress.com/gs/page/'>MyAdExpress</a>
				</li>
			</ul>
			<h1>MY RECENT DOCUMENTS</h1>
			<DocumentsTable tableData={documents} />
		</Layout>
	);
};

export default HomePage;
