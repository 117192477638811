import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { navigate } from 'gatsby';

import arngLogo from '../../assets/arng_logo.png';
import CloseIcon from '../../assets/cross.inline.svg';
import MenuIcon from '../../assets/menu.inline.svg';
import MinusIcon from '../../assets/minus.inline.svg';
import PlusIcon from '../../assets/plus.inline.svg';

import AddDocumentsModal from './AddDocumentsModal';

import '../styles/global.css';
import * as styles from '../styles/components/Layout.module.css';

const Layout = ({ children, withNavMenu, withAddDocumentsModal, className, pageName, tableData, setTableData }) => {
	const [showAddDocumentsModal, setShowAddDocumentsModal] = useState(false);
	const [showNavMenu, setShowNavMenu] = useState(false);
	const navLinks = [
		{
			text: 'PLANNING',
			href: '/app/planning-documents',
		},
		{
			text: 'TRAINING',
			href: '/app/training-documents',
		},
		{
			text: 'REPORTING',
			href: '/app/reporting-documents',
		},
		{ text: 'MEDIA SPENDS', href: '/app/media-spends' },
		{
			text: 'CONTACTS',
			href: 'https://oneomnicom.sharepoint.com/:x:/r/sites/ARNGSMSP/Shared%20Documents/Support%20Tool/Contact%20List/MNCO_CONTACT_LIST_SupportTool.xlsx?d=wb2757726716c4c32ae153cf21db1135b&csf=1&web=1&e=DscUlv',
		},
	];

	const handleContextMenuClick = () => {
		setShowAddDocumentsModal(!showAddDocumentsModal);
	};

	const handleNavMenuClick = () => {
		setShowNavMenu(!showNavMenu);
	};

	const handleLogout = async () => {
		try {
			await Auth.signOut({ global: true });
			navigate('/app/login');
		} catch (error) {
			console.log('LOG OUT FAILED: ', error);
		}
	};

	return (
		<div className={`${styles.layout}${className ? ' ' + className : ''}`}>
			<header>
				<a href='/app' className={styles.logo}>
					<img src={arngLogo} alt='ARNG Logo' />
					<div className={styles.title}>
						<strong>SMSP</strong> SUPPORT TOOL
					</div>
				</a>
				{withNavMenu && (
					<button onClick={handleNavMenuClick}>
						<MenuIcon />
					</button>
				)}
			</header>
			<main>{children}</main>
			{withAddDocumentsModal && (
				<button onClick={handleContextMenuClick} className={styles.contextMenuButton}>
					{showAddDocumentsModal ? <MinusIcon /> : <PlusIcon />}
				</button>
			)}
			<AddDocumentsModal
				isOpen={showAddDocumentsModal}
				handleClose={() => setShowAddDocumentsModal(false)}
				pageName={pageName}
				tableData={tableData}
				setTableData={setTableData}
			/>
			{showNavMenu && (
				<nav>
					<div>
						<div className={styles.controls}>
							<button onClick={handleNavMenuClick}>
								<CloseIcon />
							</button>
						</div>
						<ul>
							{navLinks.map((link, index) => (
								<li key={index}>
									<a href={link.href}>{link.text}</a>
								</li>
							))}
						</ul>
					</div>
					<div className={styles.footer}>
						<button onClick={() => handleLogout()}>LOGOUT</button>
					</div>
				</nav>
			)}
		</div>
	);
};

export default Layout;
