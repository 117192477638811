import React, { useEffect, useState } from 'react';

import EnclosedCloseIcon from '../../assets/cross_enclosed.inline.svg';
import searchIcon from '../../assets/search.png';
import thumbsUpIcon from '../../assets/thumbs_up.png';

import Button from './Button';
import Modal from './Modal';
import TextInput from './TextInput';

import * as fileUtil from '../utils/file-repo';
import * as authUtil from '../utils/auth';

import * as styles from '../styles/components/Modal.module.css';

const AddDocumentsModal = ({ isOpen, handleClose, pageName, tableData, setTableData }) => {
	const [step, setStep] = useState(1);
	const [selectedFile, setSelectedFile] = useState(null);
	const [fileUploadErrorMessage, setFileUploadErrorMessage] = useState('');
	const [tagQuery, setTagQuery] = useState('');
	const [description, setDescription] = useState('');
	const [tagList, setTagList] = useState([]);

	const resetForm = () => {
		setSelectedFile(null);
		setFileUploadErrorMessage('');
		setTagQuery('');
		setDescription('');
		setTagList([]);
	};

	const handleFileUploadChange = (event) => {
		console.log('file upload:', event.target.files[0]);
		const file = event.target.files[0];
		if (
			file &&
			file.name &&
			tableData.filter((item) => item.documentName.toUpperCase() === file.name.toUpperCase()).length > 0
		) {
			setFileUploadErrorMessage('Cannot upload file that already exists. Please upload another and try again.');
		} else {
			setFileUploadErrorMessage('');
			setSelectedFile(file);
		}
	};

	const handleAddTagSubmit = (event) => {
		event.preventDefault();
		if (tagQuery && tagQuery.length > 1 && !tagList.includes(tagQuery) && tagList <= 1) {
			console.log([...tagList, tagQuery]);
			setTagList([...tagList, tagQuery]);
		}
	};

	const handleRemoveTag = (index) => {
		let arr = [...tagList];
		arr.splice(index, 1);
		setTagList(arr);
	};

	const handleTagSearchUpdate = (event) => {
		setTagQuery(event.target.value);
	};

	const handleDescriptionChange = (event) => {
		setDescription(event.target.value);
	};

	// const uploadFile = (file, tags, description) => {
	// 	// craft prepend the filename with the path so the file is uploaded into the proper "folder" in S3
	// 	let fileNameWithPath = pageName + file.name;

	// 	uploadDocument(fileNameWithPath, description, "fakeuser", tags.join(","), file).then((result) => {
	// 		// TODO: after upload complete - reload the component to refresh the items in the table
	// 		// useEffect(() => {
	// 		// 	fetchData();
	// 		// }, [data]);
	// 	})
	// 	.catch((err) => console.log(err));
	// }

	const handleAddDocumentSubmit = async () => {
		if (
			selectedFile &&
			tableData.filter((item) => item.documentName.toUpperCase() === selectedFile.name.toUpperCase()).length === 0 &&
			tagList.length > 0 &&
			description.length > 1
		) {
			// Send out API call to attempt upload of document
			// TODO: show loading state, and add error handling/response
			setStep(2);
			const username = authUtil.getCurrentUser().username;
			console.log('Found the user name', username);
			const fileNameWithPath = pageName + selectedFile.name;
			fileUtil
				.uploadDocument(fileNameWithPath, description, username, tagList.join(','), selectedFile)
				.then((result) => {
					fileUtil.listObjectsByPrefix(pageName, setTableData);
				})
				.catch((err) => {
					console.error('There was an error uploading the document', err);
				});
			// uploadFile(selectedFile, tagList,description);
		} else {
			console.log('invalid submit');
		}
	};

	useEffect(() => {
		resetForm();
		setStep(1);
	}, [isOpen]);

	const renderContents = () => {
		switch (step) {
			case 1:
				return (
					<div>
						<h2>ADD DOCUMENT</h2>
						<div className={styles.inputContainer}>
							<label htmlFor='file'>UPLOAD DOCUMENT</label>
							{fileUploadErrorMessage && <p className={styles.error}>{fileUploadErrorMessage}</p>}
							<input id='file' type='file' name='file' onChange={handleFileUploadChange} />
						</div>
						<form className={styles.searchField} onSubmit={handleAddTagSubmit}>
							<TextInput
								type='search'
								color='light'
								label='ADD TAG'
								id='search'
								value={tagQuery}
								handleChange={handleTagSearchUpdate}
							/>
							<Button type='submit' color='white'>
								ADD
								{/* <img src={searchIcon} alt='Search Icon' className={searchIcon} /> */}
							</Button>
						</form>
						<div className={styles.tagList}>
							{tagList.map((tag, index) => (
								<div className={styles.tagListItem} key={index}>
									<button className={styles.tagDeleteButton} onClick={() => handleRemoveTag(index)}>
										<EnclosedCloseIcon />
									</button>
									{tag}
								</div>
							))}
						</div>
						<div className={styles.inputContainer}>
							<label htmlFor='description'>ADD DESCRIPTION</label>
							<textarea
								id='description'
								name='description'
								placeholder='TYPE DESCRIPTION HERE /'
								value={description}
								onChange={handleDescriptionChange}
								rows='5'
								required></textarea>
						</div>
						<div className={styles.buttonBar}>
							<Button color='yellow' handleClick={handleClose}>
								CANCEL
							</Button>
							<Button type='submit' color='black' handleClick={handleAddDocumentSubmit}>
								ADD DOCUMENT
							</Button>
						</div>
					</div>
				);
			case 2:
			default:
				return (
					<div className={styles.confirmation}>
						<img src={thumbsUpIcon} alt='Thumbs Up Icon' />
						<h3>DOCUMENT ADDED SUCCESSFULLY</h3>
						<Button color='yellow' handleClick={handleClose}>
							CLOSE
						</Button>
					</div>
				);
		}
	};

	// TODO: Add form validation, API integrations, error states, and loading spinner
	return <Modal isOpen={isOpen}>{renderContents()}</Modal>;
};

export default AddDocumentsModal;
