import React, { useEffect, useState } from 'react';

import exclamationIcon from '../../assets/exclamation.png';
import thumbsUpIcon from '../../assets/thumbs_up.png';

import Modal from './Modal';
import Button from './Button';

import { deleteObject, listObjectsByPrefix } from '../utils/file-repo';

import * as styles from '../styles/components/Modal.module.css';

const ConfirmDeleteModal = ({ isOpen, handleClose, rowData, pageName, setTableData }) => {
	const [step, setStep] = useState(1);

	const handleConfirmDelete = () => {
		deleteObject(rowData.metadataId)
			.then((response) => {
				setStep(2);
				listObjectsByPrefix(pageName, setTableData);
			})
			.catch((err) => {
				console.error('Ran into error', err);
			});
	};

	const renderContents = () => {
		switch (step) {
			case 1:
				return (
					<div className={styles.confirmation}>
						<img src={exclamationIcon} alt='Thumbs Up Icon' />
						<h2>CONFIRM DELETION OF {rowData.documentName}?</h2>
						<div className={styles.buttonBar}>
							<Button color='yellow' handleClick={handleClose}>
								CANCEL
							</Button>
							<Button type='submit' color='black' handleClick={handleConfirmDelete}>
								DELETE
							</Button>
						</div>
					</div>
				);
			case 2:
			default:
				return (
					<div className={styles.confirmation}>
						<img src={thumbsUpIcon} alt='Thumbs Up Icon' />
						<h3>DOCUMENT DELETED SUCCESSFULLY</h3>
						<Button color='yellow' handleClick={handleClose}>
							CLOSE
						</Button>
					</div>
				);
		}
	};

	useEffect(() => {
		setStep(1);
	}, [isOpen]);

	return <Modal isOpen={isOpen}>{rowData ? renderContents() : null}</Modal>;
};

export default ConfirmDeleteModal;
