import React from 'react';
import { Router } from '@reach/router';

import ForgotPasswordPage from '../components/pages/forgot-password';
import HomePage from '../components/pages/home';
import LoginPage from '../components/pages/login';
import MediaSpendsPage from '../components/pages/media-spends';
import PlanningDocumentsPage from '../components/pages/planning-documents';
import ReportingDocumentsPage from '../components/pages/reporting-documents';
import TrainingDocumentsPage from '../components/pages/training-documents';
import ProtectedRoute from '../components/ProtectedRoute';

const App = () => (
	<Router basepath='/app'>
		<ProtectedRoute path='/' component={HomePage} />
		<ProtectedRoute path='/media-spends' component={MediaSpendsPage} />
		<ProtectedRoute path='/planning-documents' component={PlanningDocumentsPage} />
		<ProtectedRoute path='/reporting-documents' component={ReportingDocumentsPage} />
		<ProtectedRoute path='/training-documents' component={TrainingDocumentsPage} />
		<ForgotPasswordPage path='/forgot-password' />
		<LoginPage path='/login' />
	</Router>
);

export const Head = () => <title>ARNG SMSP Support Tool</title>;

export default App;
