/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createDocumentMetadata = /* GraphQL */ `
  mutation CreateDocumentMetadata(
    $input: CreateDocumentMetadataInput!
    $condition: ModelDocumentMetadataConditionInput
  ) {
    createDocumentMetadata(input: $input, condition: $condition) {
      id
      s3Key
      description
      author
      tags
      createdAt
      updatedAt
    }
  }
`;
export const updateDocumentMetadata = /* GraphQL */ `
  mutation UpdateDocumentMetadata(
    $input: UpdateDocumentMetadataInput!
    $condition: ModelDocumentMetadataConditionInput
  ) {
    updateDocumentMetadata(input: $input, condition: $condition) {
      id
      s3Key
      description
      author
      tags
      createdAt
      updatedAt
    }
  }
`;
export const deleteDocumentMetadata = /* GraphQL */ `
  mutation DeleteDocumentMetadata(
    $input: DeleteDocumentMetadataInput!
    $condition: ModelDocumentMetadataConditionInput
  ) {
    deleteDocumentMetadata(input: $input, condition: $condition) {
      id
      s3Key
      description
      author
      tags
      createdAt
      updatedAt
    }
  }
`;
