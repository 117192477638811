/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDocumentMetadata = /* GraphQL */ `
  query GetDocumentMetadata($id: ID!) {
    getDocumentMetadata(id: $id) {
      id
      s3Key
      description
      author
      tags
      createdAt
      updatedAt
    }
  }
`;
export const listDocumentMetadata = /* GraphQL */ `
  query ListDocumentMetadata(
    $filter: ModelDocumentMetadataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocumentMetadata(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        s3Key
        description
        author
        tags
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
