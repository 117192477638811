import React, { useEffect } from 'react';

import * as styles from '../styles/components/Modal.module.css';

const Modal = ({ isOpen, handleClose, children, isNested }) => {
	const modalClassName = isOpen ? `${styles.modal} ${styles.show}` : `${styles.modal} ${styles.hide}`;

	useEffect(() => {
		if (isOpen) {
			if (document.body.style.overflow !== 'hidden') {
				// Lock scroll functionality when modal is open and scrolling is not already locked
				document.body.style.overflow = 'hidden';
			}
		} else {
			if (!isNested) {
				// Unlock scroll functionality when modal is closed and not nested within another modal
				document.body.style.overflow = 'unset';
			}
		}

		return () => {
			if (isOpen && !isNested) {
				// Unlock scroll functionality when modal is unmounted
				document.body.style.overflow = 'unset';
			}
		};
	}, [isOpen, isNested]);

	return (
		<div className={modalClassName} role='dialog'>
			<div className={styles.content}>{children}</div>
		</div>
	);
};

// Modal.propTypes = {
// 	isOpen: PropTypes.bool.isRequired,
// 	handleClose: PropTypes.func.isRequired,
// 	children: PropTypes.element.isRequired,
// 	isNested: PropTypes.bool,
// };

export default Modal;
