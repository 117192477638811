import React, { useEffect, useMemo, useState } from 'react';

import DownArrow from '../../assets/arrow_down.inline.svg';
import UpArrow from '../../assets/arrow_up.inline.svg';
import deleteIcon from '../../assets/delete.png';
import DotsIcon from '../../assets/dots.inline.svg';
import downloadIcon from '../../assets/download.png';
import editIcon from '../../assets/edit.png';
import filterIcon from '../../assets/filter.png';
import searchIcon from '../../assets/search.png';

import Button from './Button';
import EditDocumentsModal from './EditDocumentsModal';
import RadioInput from './RadioInput';
import TextInput from './TextInput';

import * as authUtil from '../utils/auth';
import { downloadObject } from '../utils/file-repo';

import * as styles from '../styles/components/DocumentsTable.module.css';
import ConfirmDeleteModal from './ConfirmDeleteModal';

const DocumentsTable = ({ tableData, setTableData, withFiltering, pageName }) => {
	const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
	const [showEditDocumentsModal, setShowEditDocumentsModal] = useState(false);
	const [selectedRow, setSelectedRow] = useState(null);
	const [filteredData, setFilteredData] = useState(tableData);
	const tableHeaders = [
		{ key: 'documentName', label: 'NAME' },
		{ key: 'description', label: 'DESCRIPTION' },
		{ key: 'tag', label: 'TAG' },
		{ key: 'lastModified', label: 'LAST MODIFIED' },
		{ key: 'author', label: 'AUTHOR' },
	];

	const [sortConfig, setSortConfig] = useState({ key: 'lastModified', direction: 'descending' });
	const { username } = authUtil.getCurrentUser();

	const sortedData = useMemo(() => {
		let sortableItems = [...filteredData];
		if (sortConfig !== null) {
			sortableItems.sort((a, b) => {
				if (a[sortConfig.key] && b[sortConfig.key]) {
					const itemA = a[sortConfig.key].toUpperCase();
					const itemB = b[sortConfig.key].toUpperCase();
					if (itemA < itemB) {
						return sortConfig.direction === 'ascending' ? -1 : 1;
					}
					if (itemA > itemB) {
						return sortConfig.direction === 'ascending' ? 1 : -1;
					}
				}
				return 0;
			});
		}
		return sortableItems;
	}, [filteredData, sortConfig]);

	const requestSort = (key) => {
		let direction = 'ascending';
		if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
			direction = 'descending';
		}
		setSortConfig({ key, direction });
	};

	const [searchQuery, setSearchQuery] = useState('');
	const [docTypeFilter, setDocTypeFilter] = useState(null);
	const [showFilterMenu, setShowFilterMenu] = useState(false);
	const [activeRowIndex, setActiveRowIndex] = useState(-1);
	const [showRowActionMenu, setShowRowActionMenu] = useState(false);

	const handleFilterMenuToggle = () => {
		setShowFilterMenu(!showFilterMenu);
	};

	const handleActionMenuToggle = () => {
		setShowRowActionMenu(!showRowActionMenu);
	};

	const handleSearchUpdate = (event) => {
		setSearchQuery(event.target.value);
	};

	const handleSearchSubmit = (event) => {
		event.preventDefault(); // prevent page refresh / url update
		if (searchQuery !== '') {
			// basic form validation here
			const queryResults = tableData.filter((row) => isPartialMatch(row, searchQuery));
			setFilteredData(queryResults);
		} else {
			setFilteredData(tableData);
		}
	};

	const handleDocTypeChange = (event) => {
		setDocTypeFilter(event.target.id);
	};

	const isDocTypeMatch = (row, docType) => {
		return row.documentName.toLowerCase().includes(`.${docType.toLowerCase()}`);
	};

	const isPartialMatch = (row, query) => {
		// could do this in a smarter / more dynamic ways by taking the table keys (columns) and iterating through for partial matches via forEach loop
		// though some columns may have special matching rules so keeping it separate allows for that specification (for example: if the last modifed column becomes date format, or there is an id column that is type int)
		const nameColumnMatch = row.documentName.toLowerCase().includes(query.toLowerCase());
		const descriptionColumnMatch = row.description.toLowerCase().includes(query.toLowerCase());
		const tagColumnMatch = row.tag.toLowerCase().includes(query.toLowerCase());
		const lastModifiedColumnMatch = row.lastModified.toLowerCase().includes(query.toLowerCase());
		const authorColumnMatch = row.author.toLowerCase().includes(query.toLowerCase());
		return nameColumnMatch || descriptionColumnMatch || tagColumnMatch || lastModifiedColumnMatch || authorColumnMatch;
	};

	const handleMouseEnter = (index) => {
		setActiveRowIndex(index);
	};

	const handleMouseLeave = () => {
		setShowRowActionMenu(false);
		setActiveRowIndex(-1);
	};

	const handleRowDeleteClick = (index) => {
		setSelectedRow(sortedData[index]);
		setShowConfirmDeleteModal(true);
	};

	const handleRowDownloadClick = async (index) => {
		const row = sortedData[index];
		console.debug('Executing download for row', row);
		const returnObj = await downloadObject(row.metadataId);
		const url = URL.createObjectURL(returnObj.Body);
		const a = document.createElement('a');
		a.href = url;
		a.download = row.documentName || 'download';
		const clickHandler = () => {
			setTimeout(() => {
				URL.revokeObjectURL(url);
				a.removeEventListener('click', clickHandler);
			}, 150);
		};
		a.addEventListener('click', clickHandler, false);
		a.click();
		return a;
	};

	const handleRowEditClick = (index) => {
		setSelectedRow(sortedData[index]);
		setShowEditDocumentsModal(true);
	};

	const resetTable = () => {
		setSearchQuery('');
		setDocTypeFilter(null);
		setFilteredData(tableData);
	};

	useEffect(() => {
		if (docTypeFilter) {
			if (docTypeFilter !== 'other') {
				const queryResults = tableData.filter((row) => isDocTypeMatch(row, docTypeFilter));
				setFilteredData(queryResults);
			} else {
				const queryResults = tableData.filter(
					(row) => !isDocTypeMatch(row, 'pdf') && !isDocTypeMatch(row, 'docx') && !isDocTypeMatch(row, 'xls'),
				);
				setFilteredData(queryResults);
			}
		}
	}, [docTypeFilter]);

	useEffect(() => {
		// reset row action menu when filters change or filter menu is brought up
		setShowFilterMenu(false);
		setShowRowActionMenu(false);
		setActiveRowIndex(-1);
	}, [filteredData, sortConfig]);

	useEffect(() => {
		setFilteredData(tableData);
	}, [tableData]);

	return (
		<div className={styles.tableContainer}>
			{withFiltering && (
				<div className={styles.tableControls}>
					<form className={styles.searchField} onSubmit={handleSearchSubmit}>
						<TextInput
							type='search'
							label='Search for...'
							id='search'
							value={searchQuery}
							handleChange={handleSearchUpdate}
						/>
						<button type='submit'>
							<img src={searchIcon} alt='Search Icon' />
						</button>
					</form>
					{tableData && tableData.length !== sortedData.length && (
						<Button handleClick={resetTable}>RESET FILTERS</Button>
					)}
				</div>
			)}
			<table>
				<thead>
					<tr>
						{tableHeaders.map((header, index) => (
							<th key={index}>
								<button onClick={() => requestSort(header.key)} className={styles.headerButton}>
									{header.label}
									{sortConfig && sortConfig.key === header.key ? (
										sortConfig.direction === 'ascending' ? (
											<DownArrow />
										) : (
											<UpArrow />
										)
									) : null}
								</button>
							</th>
						))}
						<th>
							{withFiltering && (
								<button onClick={handleFilterMenuToggle} className={styles.filterButton}>
									<img src={filterIcon} alt='Filter Icon' />
								</button>
							)}
							{showFilterMenu && (
								<div className={styles.filterMenu}>
									<h2>FILTER</h2>
									<fieldset>
										<legend>DOC TYPE</legend>
										<div className={styles.row}>
											<RadioInput
												id='pdf'
												name='docType'
												className={styles.half}
												handleChange={handleDocTypeChange}
												checked={docTypeFilter === 'pdf'}>
												PDF
											</RadioInput>
											<RadioInput
												id='docx'
												name='docType'
												className={styles.half}
												handleChange={handleDocTypeChange}
												checked={docTypeFilter === 'docx'}>
												DOCX
											</RadioInput>
										</div>
										<div className={styles.row}>
											<RadioInput
												id='xls'
												name='docType'
												className={styles.half}
												handleChange={handleDocTypeChange}
												checked={docTypeFilter === 'xls'}>
												XLS
											</RadioInput>
											<RadioInput
												id='other'
												name='docType'
												className={styles.half}
												handleChange={handleDocTypeChange}
												checked={docTypeFilter === 'other'}>
												OTHER
											</RadioInput>
										</div>
									</fieldset>
								</div>
							)}
						</th>
					</tr>
				</thead>
				<tbody>
					{/* Would ideally have some error catching / empty table state for non-matched queries here */}
					{sortedData?.map((row, index) => (
						<tr key={index} onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={() => handleMouseLeave()}>
							<td>{row.documentName}</td>
							<td>{row.description}</td>
							<td>{row.tag}</td>
							<td>
								<i>{row.lastModified}</i>
							</td>
							<td>{row.author}</td>
							<td>
								{activeRowIndex === index && (
									<button onClick={handleActionMenuToggle}>
										<DotsIcon />
									</button>
								)}
								{showRowActionMenu && activeRowIndex === index && (
									<div className={styles.rowActionMenu}>
										{username === row.author && (
											<button onClick={() => handleRowDeleteClick(index)}>
												<img src={deleteIcon} alt='Delete Icon' />
												DELETE
											</button>
										)}
										<button onClick={() => handleRowEditClick(index)}>
											<img src={editIcon} alt='Edit Icon' />
											EDIT
										</button>
										<button onClick={() => handleRowDownloadClick(index)}>
											<img src={downloadIcon} alt='Download Icon' />
											DOWNLOAD
										</button>
									</div>
								)}
							</td>
						</tr>
					))}
				</tbody>
			</table>
			<ConfirmDeleteModal
				isOpen={showConfirmDeleteModal}
				handleClose={() => setShowConfirmDeleteModal(false)}
				rowData={selectedRow}
				pageName={pageName}
				setTableData={setTableData}
			/>
			<EditDocumentsModal
				isOpen={showEditDocumentsModal}
				handleClose={() => setShowEditDocumentsModal(false)}
				rowData={selectedRow}
				pageName={pageName}
				setTableData={setTableData}
			/>
		</div>
	);
};

export default DocumentsTable;
