import { API, Storage, Amplify } from 'aws-amplify';
import awsconfig from '../aws-exports';
import * as queries from '../graphql/queries'
import * as mutations from "../graphql/mutations"
// import { query } from 'gatsby-theme-portfolio-minimal/src/hooks/useSiteConfiguration';
Amplify.configure(awsconfig)



/**
 * Helper function for pulling files by the prefix name
 *  Wrapper for Amplify Storage functions https://docs.amplify.aws/lib/storage/list/q/platform/js/
 *  https://docs.amplify.aws/lib/storage/list/q/platform/js/#public-level-list
 * @param {*} s3Prefix  
 * @param {*} setDocuments 
 */
export const listObjectsByPrefix = async (s3Prefix, setDocuments) => {
    console.log("s3Prefix", s3Prefix);

    // Retrieve a list of metadata where the S3 key starts w/ the input s3Prefix
    let filter = {
        id: {
            beginsWith: s3Prefix // filter priority = 1
        }
    };
    const apiReturn = await API.graphql({
        query: queries.listDocumentMetadata,
        variables: {
            filter: filter
        }
    });

    const metadataList = apiReturn.data.listDocumentMetadata.items;

    // Retrieve the list of S3 files for the given prefix'
    //  NOTE: the "folder" path gets returned, so we filter out anything where the size is "falsy"/0 or empty
    const s3FileList = (await Storage.list(s3Prefix, { maxKeys: 'ALL' })).filter((i) => { return i.size });
    // remove the entry for the empty prefix
    console.log("s3FileList", s3FileList);

    const dateFormatOptions = { year: '2-digit', month: '2-digit', day: '2-digit' };

    // Iterate through the s3FileList using a map function
    const files = s3FileList.map((i) => {
        const s3Key = i.key.replace(s3Prefix, '');
        console.log("Comparing", i.key)
        let metadataObj = metadataList.find(element => element.id === i.key);
        console.log("Find returned:", metadataObj)
        // there is no MetaData for the S3 Object, create a Metadata object in Dynamo
        if (!metadataObj) {
            metadataObj = {
                "id": i.key,
                "s3Key": i.key,
                "author": "SYSTEM",
            };
            API.graphql({
                query: mutations.createDocumentMetadata,
                variables: {
                    input: metadataObj
                }
            }).then((result) => console.log("Saved", result))
              .catch((err) => console.error("error creating metadata object", err));
        }
        return {
            metadataId: metadataObj.id,
            documentName: metadataObj.s3Key.replace(s3Prefix, ""),
            description: metadataObj.description || "",
            tag: metadataObj.tags || "",
            lastModified: metadataObj.updatedAt,
            author: metadataObj.author || "",
        }
    });
    setDocuments(files);

    // clean up orphan metadata objects
    metadataList.forEach(metadata => {
        const matchingS3Obj = s3FileList.find(s3Obj => s3Obj.key === metadata.id);

        if (!matchingS3Obj) {
            const docMetadata = {
                id: metadata.id,
            };
            API.graphql({
                query: mutations.deleteDocumentMetadata,
                variables: { input: docMetadata }
            })
            .then((result) => console.log("Removed metadata for: ", result))
            .catch((err) => console.warn("Could not delete during clean up", err));
        }
    });


}


const s3PrefixRegex = /^(planning-documents\/|training-documents\/|reporting-documents\/|media-spend-documents\/)/;
/**
 * @param {*} setDocuments 
 */
 export const listUserRecentDocuments = async (username) => {
    // Retrieve a list of metadata where the S3 key starts w/ the input s3Prefix
    let filter = {
        author: {
            eq: username // filter priority = 1
        }
    };
    const apiReturn = await API.graphql({
        query: queries.listDocumentMetadata,
        variables: {
            filter: filter
        }
    })
    const metadataList = apiReturn.data.listDocumentMetadata.items.sort((a,b) => a.updatedAt > b.updatedAt).slice(0,3);

    const docList = metadataList.map((x) => {
        return {
            metadataId: x.id,
            documentName: x.s3Key.replace(s3PrefixRegex, ""),
            description: x.description || "",
            tag: x.tags || "",
            lastModified: x.updatedAt,
            author: x.author || "",
        };
    });
    return docList;    
}


/**
 * Upload Document 
* @param {*} s3Key 
 * @param {*} description 
 * @param {*} author 
 * @param {*} tags 
 * @param {*} file 
 */
export const uploadDocument = async (s3Key, description, author, tags, file) => {

    const metadata = {
        id: s3Key,
        s3Key: s3Key,
        description: description || "",
        tags: tags || "",
        author: author
    };
    try {
        // Add the metadata
        const metadataSaveResponse = await API.graphql({
            query: mutations.createDocumentMetadata,
            variables: {
                input: metadata
            }
        });

        // Uploading contents to S3
        //  Ref: https://docs.amplify.aws/lib/storage/upload/q/platform/js/
        const storageResponse = await Storage.put(s3Key, file);

    } catch (error) {
        console.error(error);
    }
}

/**
 * Updates **JUST** the DocumentMetadata does nothing w/ the S3 Object!!
 * @param {*} s3Key 
 * @param {*} description 
 * @param {*} tags 
 */
export const updateDocumentMetadata = async (s3Key, description, tags) => {

    // will this return as an array or a single object?
    try {
        const updatedMetadata = {
            id: s3Key, 
            description: description,
            tags: tags
        };
        
        const result = await API.graphql({
            query: mutations.updateDocumentMetadata,
            variables: {
                input: updatedMetadata
            }
        });
        console.log("Successfully updated: ", result)
        return result;
    } catch (error) {
        console.error(error);
        throw "Error updating document, please try again"
    }
}

/**
 * Download functionality
 * TODO - still needs to be done
 * https://docs.amplify.aws/lib/storage/download/q/platform/js/
 * @param {*} s3Key 
 */
export const downloadObject = async (s3Key) => {
    // Example code below from the Reference from the link above
    const returnObj = await Storage.get(s3Key, {download: true});
    console.log("The return object from the download call: ", returnObj);
    return returnObj;
}

export const deleteObject = async (s3Key) => {
    // Example code below from the Reference from the link above
    try {
        // delete the file from S3
        await Storage.remove(s3Key);
        const docMetadata = {
            id: s3Key,
        };

        const deleteResponse = await API.graphql({ 
            query: mutations.deleteDocumentMetadata, 
            variables: { input: docMetadata } 
        });
        console.log("Deleted object", deleteResponse)
    } catch (err) {
        console.error("Error deleting object", err);
    }
}