import React from 'react';

import * as styles from '../styles/components/TextInput.module.css';

function TextInput({
	type = 'text',
	color = 'dark',
	label,
	id,
	autoComplete,
	value,
	handleBlur,
	handleChange,
	required,
	pattern,
	disabled = false,
}) {
	const className = `${styles.inputContainer} ${styles[color]}`;
	return (
		<div className={className}>
			<input
				id={id}
				name={id}
				type={type}
				value={value}
				onBlur={handleBlur}
				onChange={handleChange}
				autoComplete={autoComplete}
				required={required}
				pattern={pattern}
				disabled={disabled}
				placeholder=' '
			/>
			<label className={value && styles.filled} htmlFor={id}>
				{label}
			</label>
		</div>
	);
}

export default TextInput;
