import React from 'react';
import * as styles from '../styles/components/Button.module.css';

const Button = ({ children, handleClick, type = 'button', color = 'yellow', disabled = false }) => {
	const buttonClassname = `${styles.button} ${styles[color]}`;
	return (
		<button type={type} className={buttonClassname} onClick={handleClick} disabled={disabled}>
			{children}
		</button>
	);
};

export default Button;
